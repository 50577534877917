import React from "react";
import Map from "../../left_component/Map";

const Ride = () => {
  return (
    <div>
      <Map />
    </div>
  );
};

export default Ride;
